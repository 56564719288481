import { AppConstants } from '@shared/constants';
import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ProjectService } from "@entities/project/project.service";
import {
  Group,
  IFilter,
} from "@entities/utilization-management/utilization.model";
import { ButtonParams } from "@shared/models";
import { MultiSelect } from "primeng/multiselect";
import { SflBaseComponent } from "../sfl-base/sfl-base.component";
import moment from 'moment';
import { PNGTree, TreeViewStructure } from '@entities/administration/append-tags/tree-view-model';

@Component({
  selector: "app-apply-filter",
  templateUrl: "./apply-filter.component.html",
  styleUrls: ["./apply-filter.component.scss"],
})
export class ApplyFilterComponent extends SflBaseComponent implements OnInit, AfterViewInit, OnChanges {
  _clientGroups: Group[];
  _projectGroups: Group[];

  @Input() selectedStatus: string[];

  @Input() filterData: IFilter;
  @Output() filterDataChange = new EventEmitter<IFilter>();

  @Output() getClientsIds = new EventEmitter<IFilter>();
  @Output() getProjectsIds = new EventEmitter<IFilter>();

  @Input() set clientGroups(value: Group[]) {
    this._clientGroups = value;
  }
  @Input() set projectGroups(value: Group[]) {
    this._projectGroups = value;
  }

  @Input() client: Group[];
  @Input() project: Group[];
  @Input() tags: TreeViewStructure;
  @Input() savedSelectedTags;

  @Input() statuses = [];
  @Input() defaultSelectedStatuses = []

  dateError = false;
  dateRequired = false;
  filterCardTitle = "Apply Filter";
  filterButtons: ButtonParams[] = [
    {
      btnClass: "btn-close-icon",
      btnIcon: "times",
      action: this.onClose.bind(this),
    },
  ];
  @Input() sidebarIcons: any;
  @Output() closeSidebarEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('multiSelectComp') multiSelectComp: MultiSelect;
  minDate = null;
  selectedCategoriesTag: PNGTree[] = [];
  selectedTags = [];
  finalTagsAlongWithTheCategory: string[] = [];

  constructor(private readonly datePipe: DatePipe, private readonly projectService: ProjectService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('sidebarIcons')) {
      this.filterButtons.unshift(...this.sidebarIcons);
    }
  }

  ngAfterViewInit() {
    this.multiSelectComp.options = this.statuses;
  }

  ngOnInit(): void {
    this.getEffectiveDates();

  }

  getEffectiveDates() {
    this.subscriptionManager.add(this.projectService.getProjectEffectiveDates().subscribe((res) => {
      if (res?.dates?.length) {
        const firstIndexDate = [...res.dates].shift();
        this.minDate = moment(firstIndexDate).toDate();
      }
    }))
  }

  onReset() {
    this.filterData = {};
    this.savedSelectedTags = []
    this.filterData.include_utilizations = true;
    this.filterData.include_work_exceptions = true;
    this.filterData.statuses = this.defaultSelectedStatuses.toString();
    this.filterData.status = this.defaultSelectedStatuses;
    const date = new Date();
    this.filterData.period = 'custom';
    this.filterData.rollingOption = "Current plus 2 months";
  }

  onSubmit() {
    if (this.filterData.start_month || this.filterData.end_month) {
      if (!this.filterData.start_month) {
        this.dateRequired = true;
      }
      if (!this.filterData.end_month) {
        this.dateRequired = true;
      }
    }
    if (!this.dateError && !this.dateRequired) {
      this.onClose(true);
    }
  }

  tagSelected(event: any) {
    this.filterData.tags = this.finalizedTags(event)
  }

  finalizedTags(event: any): string {
    const categoryAndTags = event;
    this.finalTagsAlongWithTheCategory = []
    this.selectedCategoriesTag = categoryAndTags.filter(tag => !(tag.hasOwnProperty('selectable')));
    for (const selectedTags of this.selectedCategoriesTag) {
      let labelHolder = '';
      labelHolder += selectedTags.label
      if (selectedTags.parent) {
        labelHolder = selectedTags.parent.label + '__' + labelHolder
        if (selectedTags.parent?.parent) {
          labelHolder = 'equals:' + selectedTags.parent?.parent?.label + '__' + labelHolder
          if (selectedTags.parent?.parent?.parent) {
            labelHolder = selectedTags.parent?.parent?.parent?.label + '__' + labelHolder
          }
        }
      }
      this.finalTagsAlongWithTheCategory.push(labelHolder);
    }
    return this.finalTagsAlongWithTheCategory.toString();
  }

  _getClientsIds() {
    this.filterDataChange.emit(this.filterData);
    this.getClientsIds.emit();
  }

  _getProjectsIds() {
    this.filterDataChange.emit(this.filterData);
    this.getProjectsIds.emit();
  }

  onClientSelect() {
    if (!this.filterData?.client?.value) {
      this.filterData.client = null;
      this.filterDataChange.emit(this.filterData);
    }
  }

  startMonthSelected(event) {
    this.dateError = false;
    this.dateRequired = false;
    this.filterData.start_date = this.datePipe.transform(event, 'yyyy-MM-dd');
    this.filterData.start_month = moment(this.filterData.start_date).toDate()
    if (this.filterData.start_date && this.filterData.end_date) {
      if (new Date(this.filterData.start_date) > new Date(this.filterData.end_date)) {
        this.dateError = true;
      }
    }
  }

  endMonthSelected(event) {
    this.dateError = false;
    this.dateRequired = false;
    let date = new Date(event);
    date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.filterData.end_date = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.filterData.end_month = moment(this.filterData.end_date).toDate()
    if (this.filterData.start_date && this.filterData.end_date) {
      if (new Date(this.filterData.start_date) > new Date(this.filterData.end_date)) {
        this.dateError = true;
      }
    }
  }

  onClose(isSubmit) {
    if (isSubmit) {
      if (this.filterData?.dataType !== 'specific') {
        delete this.filterData.effective_date;
      }
      this.filterDataChange.emit(this.filterData);
    }

    this.closeSidebarEvent.next(isSubmit);
  }

  statusSelected(event) {
    this.filterData.statuses = event?.value?.toString();
  }

  showSaveClientFilterSelected() {
    this.filterData.showClientFilter = !this.filterData.showClientFilter;
    this.filterData.customer_ids = null;
    this.filterData.customer_name = null;
    this.filterData.ClientName = [];
    this.filterData.clientName = null;
  }
  showSaveProjectFilterSelected() {
    this.filterData.showProjectFilter = !this.filterData.showProjectFilter;
    this.filterData.project_ids = null;
    this.filterData.project_name = null;
    this.filterData.projectName = [];
    this.filterData.projectNameList = null;
  }

  clientSelected(event) {
    const cIds = []
    event.value.forEach(value => {
      cIds.push(value.value);
    })
    this.filterData.selectedClient = event.value;
    this.filterData.customer_ids = cIds.join(',');
    const clientName = this.client.filter(elist => elist.value?.value == event.itemValue?.value);
    let selectedClient = this.filterData.ClientName ? this.filterData.ClientName : [];
    if (clientName.length && clientName[0]?.label) {
      if (selectedClient?.includes(clientName[0]?.label)) {
        selectedClient = selectedClient.filter(emp => emp !== clientName[0].label);

      } else {
        selectedClient.push(clientName[0]?.label);
      }
    }
    this.filterData.ClientName = selectedClient;
  }

  projectSelected(event) {
    const pIds = []
    event.value.forEach(value => {
      pIds.push(value.value);
    })
    this.filterData.selectedProject = event.value;
    this.filterData.project_ids = pIds.join(',');
    const projectName = this.project.filter(elist => elist.value?.value == event.itemValue?.value);
    let selectedProject = this.filterData.projectName ? this.filterData.projectName : [];
    if (projectName.length && projectName[0]?.label) {
      if (selectedProject?.includes(projectName[0]?.label)) {
        selectedProject = selectedProject.filter(emp => emp !== projectName[0].label);

      } else {
        selectedProject.push(projectName[0]?.label);
      }
    }
    this.filterData.projectName = selectedProject;
  }

  getClientProject(params) {
    this.subscriptionManager.add(this.projectService.getProjectData(params).subscribe(res => {
      if (this.filterData.clientProjectIds) {
        res.body.data.projects.forEach(p => {
          this.filterData.clientProjectIds.push(p.project.id);
        })
      } else {
        this.filterData.clientProjectIds = [];
        res.body.data.projects.forEach(p => {
          this.filterData.clientProjectIds.push(p.project.id);
        })
      }
    }))
  }

}
