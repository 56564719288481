import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from '@entities/theme/base/base.component';
import { ForbiddenComponent } from '@shared/components/forbidden/forbidden.component';
import { PermissionModules } from '@shared/models/permission.enum';
import { AuthGuardService } from '@shared/services/auth-guard.service';
import { PermissionAuthGuard } from '@shared/services/permission-auth-guard.service';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./@auth/auth.module').then(m => m.AuthModule)
  },
  { path: 'error', loadChildren: () => import('@entities/error/error.module').then(m => m.ErrorModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'people',
        loadChildren: () => import('@entities/manage-people/manage-people.module').then(m => m.MangePeopleModule),
        canActivate: [PermissionAuthGuard],
        data: {
          permissionModules: [PermissionModules.VIEW_EMPLOYEE, PermissionModules.MANAGE_EMPLOYEE, PermissionModules.MANAGE_WORK_EXCEPTION, PermissionModules.VIEW_WORK_EXCEPTION, PermissionModules.VIEW_IMPORT_ACTUALS, PermissionModules.MANAGE_IMPORT_ACTUALS, PermissionModules.VIEW_TIME_ENTRIES, PermissionModules.Create_TIME_ENTRIES, PermissionModules.View_Create_Entries]
        }
      },
      {
        path: 'client',
        loadChildren: () => import('@entities/client/client.module').then(m => m.ClientModule),
        canActivate: [PermissionAuthGuard],
        data: {
          permissionModules: [PermissionModules.VIEW_CLIENT, PermissionModules.MANAGE_CLIENT]
        }
      },
      {
        path: 'project',
        loadChildren: () => import('@entities/project/project.module').then(m => m.ProjectModule),
        canActivate: [PermissionAuthGuard],
        data: {
          permissionModules: [PermissionModules.VIEW_PROJECT, PermissionModules.MANAGE_PROJECT]
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('@entities/administration/administation.module').then(m => m.AdministationModule),
        canActivate: [PermissionAuthGuard],
        data: {
          // permissionModules: [PermissionModules.VIEW_ROLE, PermissionModules.MANAGE_ROLE]
          permissionModules: [
            PermissionModules.VIEW_EMPLOYEE_TYPE,
            PermissionModules.MANAGE_EMPLOYEE_TYPE,
            PermissionModules.VIEW_POSITION_TYPE,
            PermissionModules.MANAGE_POSITION_TYPE,
            PermissionModules.VIEW_WORK_EXCEPTION,
            PermissionModules.MANAGE_WORK_EXCEPTION,
            PermissionModules.VIEW_EXPENSE_TYPE,
            PermissionModules.MANAGE_EXPENSE_TYPE,
            PermissionModules.VIEW_MONTHLY_EXPENSE_TYPE,
            PermissionModules.MANAGE_MONTHLY_EXPENSE_TYPE,
            PermissionModules.VIEW_MANAGE_TAG_CATEGORY,
            PermissionModules.MANAGE_TAG_CATEGORY,
            PermissionModules.VIEW_MANAGE_TAG_SUB_CATEGORY,
            PermissionModules.MANAGE_TAG_SUB_CATEGORY,
            PermissionModules.VIEW_MANAGE_TAG,
            PermissionModules.MANAGE_TAG
          ]
        }
      },
      {
        path: 'utilization-management',
        loadChildren: () => import('@entities/utilization-management/utilization-management.module').then(m => m.UtilizationManagementModule),
        canActivate: [PermissionAuthGuard],
        data: {
          permissionModules: [PermissionModules.VIEW_BENCH_REPORT, PermissionModules.MANAGE_BENCH_REPORT, PermissionModules.MANAGE_OPEN_POSITION_REPORT, PermissionModules.VIEW_OPEN_POSITION_REPORT, PermissionModules.MANAGE_STAFFED_POSITION_REPORT, PermissionModules.VIEW_STAFFED_POSITION_REPORT]
        }
      },
      {
        path: 'financial-forecasting',
        loadChildren: () => import('@entities/financial-forecasting/financial-forecasting.module').then(m => m.FinancialForecastingModule),
        canActivate: [PermissionAuthGuard],
        data: {
          permissionModules: [PermissionModules.VIEW_PL, PermissionModules.MANAGE_PL, PermissionModules.VIEW_COMPARE_PL, PermissionModules.MANAGE_COMPARE_PL]
        }
      },
      {
        path: 'my-profile',
        loadChildren: () => import('@entities/my-profile/my-profile/my-profile.module').then(m => m.MyProfileModule)
      },
      {
        path: 'access-control',
        loadChildren: () => import('@entities/access-control/access-control.module').then(m => m.AccessControlModule),
        canActivate: [PermissionAuthGuard],
        data: {
          permissionModules: [PermissionModules.VIEW_ROLE, PermissionModules.MANAGE_ROLE]
        }
      },
      { path: 'forbidden', component: ForbiddenComponent, pathMatch: 'full' },
      { path: '', redirectTo: 'project', pathMatch: 'full' },
      { path: '**', redirectTo: 'project', pathMatch: 'full' }
    ],
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
