/**
 * @sunflowerlab
 * @author Ashish Kumar
 */

export class AppConstants {
  public static readonly authenticationToken: string = "authenticationToken";
  public static readonly refreshToken: string = "refreshToken";
  public static readonly userKey: string = "user";
  public static readonly format: string = "yyyy-MM-dd";
  public static readonly grossMargin: string = "Gross Margin";
  public static readonly height: string = "calc((var(--fixed-content-height, 1vh) * 100) - 120px)";
  public static readonly requiredMinLength: number = 3;
  public static readonly requiredMaxLength: number = 320;
  public static readonly passwordMaxLength: number = 100;
  public static readonly pointHoverOnGraph: number = 0.1;
  public static readonly pageSize: number = 10;
  public static readonly number: number = 1000;
  public static readonly minuteNumber: number = 60;
  public static readonly hoursNumber: number = 60;
  public static readonly dayNumber: number = 24;
  public static readonly secondNumber: number = 45;
  public static readonly MonthNumber: number = 30.416;
  public static readonly yearsNumber: number = 365;
  public static readonly duration: number = 2000;
  public static readonly _undoButtonDuration: number = 3000;
  public static readonly animateValue: number = 800;
  public static readonly setTimeOut: 300;
  public static readonly createPNLPlug = "Adjustment Successfully Created";
  public static readonly editPNLPlug = "Adjustment Successfully Updated";
  public static readonly shareFilter = "Filter has been shared successfully";
  public static readonly unShareFilter = "Filter has been unshared successfully";
  public static readonly deleteFilter = "Filter has been deleted successfully";
  public static readonly updateFilter = "Filter has been updated successfully";
  public static readonly defaultRole = "Default Role";
  public static readonly filterLinkCopied = "Copied sharable link to clipboard.";
  public static readonly problemFetchingFilterById = "There was a problem fetching your filter.";
  public static readonly costOfGoodsSold = "Cost of Goods Sold";
  public static readonly Underdevelopment = "Under development";
  public static readonly somethingWentWrong = "Something Went Wrong"
  public static readonly noDataFound = "No Data Found"
  public static readonly DateFormat = "MM/dd/YYYY"
}
