/**
 * @sunflowerlab
 * @author Ashish Kumar
 */
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiUrl, APP_ROUTES, AppConstants } from "../@shared/constants";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { User } from "../@shared/models/user.model";
import { Router } from "@angular/router";
import { OGantryHttpResponse } from '@shared/models';
import { ReleaseVersion, SocialAuthRequest, SocialLoginOption } from "./auth.model";
import { MenuConfigService } from '@shared/services/menu-config.service';
import { LayoutUtilsService } from "@shared/services/layout-utils.service";
import { Utility } from '@shared/utils/utils';
import { GlobalDetailList, Roles } from "@entities/administration/administration.model";
import { SocialNewAuthResponse } from ".";
import { defaultPermission } from '@shared/models/permission.enum'
export interface AuthResponse {
  access_token: string;
  renewal_token: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentPermissions = [];
  currentRole = '';
  globalDetailsList$: BehaviorSubject<GlobalDetailList> = new BehaviorSubject<GlobalDetailList>(null);
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly menuConfigService: MenuConfigService,
    private readonly layoutUtilsService: LayoutUtilsService
  ) { }

  login(credentials): Observable<string> {
    const data = {
      user: {
        email: credentials.email,
        password: credentials.password
      }
    };
    return this.http
      .post(ApiUrl.login, data)
      .pipe(map(res => this.authenticateSuccess(res)));
  }

  authenticateSuccess(resp) {
    const bearerToken = resp.data.access_token;
    if (bearerToken && window.localStorage) {
      this.setAuthTokens(resp);
      return bearerToken;
    }
  }

  register(registerUser): Observable<string> {
    return this.http.post<string>(ApiUrl.register, registerUser);
  }

  logout() {
    this.deleteSession().subscribe();
    this.stopRefreshTokenTimer();
    localStorage.removeItem(AppConstants.authenticationToken);
    localStorage.removeItem(AppConstants.refreshToken);
    sessionStorage.removeItem(AppConstants.userKey);
    localStorage.removeItem('role');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('cacheFilters');
    localStorage.removeItem('selectedColumnsArray');
    localStorage.removeItem('selectedColumnMappingForTimeSheet');
    this.currentPermissions = [];
    this.router.navigateByUrl(APP_ROUTES.LOGIN);
  }

  deleteSession() {
    return this.http.delete(ApiUrl.login);
  }

  getAccount(): Observable<User> {
    return this.http.get<User>(ApiUrl.account);
  }

  forgetPasswordInit(email: string): Observable<void> {
    return this.http.post<void>(ApiUrl.forgetPassword.init, { email });
  }

  refreshToken() {
    return this.http.post<OGantryHttpResponse<AuthResponse>>(ApiUrl.renewSession, {}, { withCredentials: true })
      .pipe(map((user) => {
        this.setAuthTokens(user);
        return user;
      }));
  }

  setAuthTokens(user) {
    localStorage.setItem(AppConstants.authenticationToken, user.data.access_token);
    localStorage.setItem(AppConstants.refreshToken, user.data.renewal_token);
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer(userToken) {

    const expiringInMinutes = 30;
    const expireDate = new Date();
    expireDate.setMinutes(expireDate.getMinutes() + expiringInMinutes);
    const expires = new Date(expireDate);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken().subscribe()
    }, timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  socialLogin(redirect_uri: string, socialLoginType: SocialLoginOption): Observable<OGantryHttpResponse<SocialNewAuthResponse>> {
    let url = '';
    switch (socialLoginType) {
      case SocialLoginOption.GOOGLE:
        url = `${ApiUrl.googleAuth}/new`;
        break;
      case SocialLoginOption.GITHUB:
        url = `${ApiUrl.githubAuth}/new`;
        break;
      case SocialLoginOption.MICROSOFT:
        url = `${ApiUrl.microsoftAuth}/new`;
        break;
    }
    return this.http.get<OGantryHttpResponse<SocialNewAuthResponse>>(url, { params: { redirect_uri } });
  }

  completeSocialLogin(googleAuthReq: SocialAuthRequest, socialLoginType: SocialLoginOption): Observable<OGantryHttpResponse<AuthResponse>> {
    switch (socialLoginType) {
      case SocialLoginOption.GOOGLE:
        return this.http.post<OGantryHttpResponse<AuthResponse>>(`${ApiUrl.googleAuth}/callback`, { data: googleAuthReq })
          .pipe(map(res => this.authenticateSuccess(res)));
      case SocialLoginOption.GITHUB:
        return this.http.post<OGantryHttpResponse<AuthResponse>>(`${ApiUrl.githubAuth}/callback`, { data: googleAuthReq })
          .pipe(map(res => this.authenticateSuccess(res)));
      case SocialLoginOption.MICROSOFT:
        return this.http.post<OGantryHttpResponse<AuthResponse>>(`${ApiUrl.microsoftAuth}/callback`, { data: googleAuthReq })
          .pipe(map(res => this.authenticateSuccess(res)));
    }
  }

  /**
   * @whatitdoes
   * Checks if the @param permissionModules are present in current permissions of the logged in user.
   * @returns true is the user is authorized to access the page/route.
   */
  async isPermittedAction(permissionModules): Promise<boolean> {
    if (!permissionModules || !permissionModules.length) {
      // If a route or page doesn't have permissionModules in its data in route config, that becomes a global route.
      return true;
    }
    const authorizedPermission = await this.getPermissions();
    if (localStorage.getItem('role').toLowerCase() === 'admin') {
      return true;
    }
    return permissionModules.some(module => authorizedPermission.includes(module));
  }

  async getPermissions(): Promise<any> {
    let currentUser: User;
    if (!this.currentPermissions.length) {
      currentUser = await this.getCurrentUser();
      if (localStorage.getItem('role').toLowerCase() === 'admin') {
        return true;
      }
      if (currentUser && currentUser?.user?.permissions) {
        this.currentPermissions = currentUser?.user?.permissions;
      } else {
        this.currentPermissions = []
      }
    }
    if (!this.currentPermissions.length) {
      // if the user is not assigned any permissions, we are going to assign them with default user permissions, so that they can atleast login to the system and have readonly/minimum access to the product.
      // grab the default permissions from global details api and if the role matches with the user role, assign the permissions to the user else assign the user default permissions.
      const globalDetails = await this.getPermissionsForRoles().toPromise();
      const curentUserRole = localStorage.getItem('role').toLowerCase();
      if (globalDetails?.data?.global_details) {
        const roles: Roles[] = globalDetails?.data?.global_details[0]?.global_detail?.extended_fields?.roles;
        // check if the role name matches with the user role curentUserRole
        const role = roles.find(role => role.name === curentUserRole);
        if (role) {
          this.currentPermissions = [{ permissions: role.permissions }];
        } else {
          // look for the role name 'Default Role' in the roles array then assign the permissions to the user from the default role
          const dPermissions = roles.find(role => role.name === AppConstants.defaultRole);
          if (dPermissions) {
            this.currentPermissions = [{ permissions: dPermissions.permissions }];
          } else {
            // tenant doesn't have any global details set up yet, so assign the user default permissions.
            this.currentPermissions = defaultPermission;
          }
        }
      } else {
        // this user belongs to a tenant which doesn't have any global details set up yet, so assign the user default permissions.
        this.currentPermissions = defaultPermission;
      }
      // If current user is not found in localStorage, it fetches currentUser from database and sets the permissions accordingly.
      // this.logout();
      // this.layoutUtilsService.showActionNotification('There are no permissions assigned', AlertType.Error);
      // return [];
    }
    let permission = JSON.parse(JSON.stringify(this.currentPermissions))
    // permission = permission[0]?.permissions
    // permission = permission?.map(p => p.subfeature).flat().map(a => {
    //   let permissionArray = [];
    //   if (a.permission) {
    //     // permissionArray = [a.permission]
    //     return a.permission;
    //   }
    //   if (a.subfeature)
    //   // else
    //   {
    //     return a.subfeature.flat().map(b => b.permission).flat();
    //   }
    // });

    permission = permission.map(a => {
      return this.extractPermission(a.permissions).flat()
    });
    return Promise.all(permission.flat());
  }


  extractPermission(subArray: any) {
    const permission = subArray.map(a => {
      let permissionArray: any = [];
      if (a.permission) {
        permissionArray = [...a.permission]
      }
      if (a.subfeature) {
        permissionArray = [...permissionArray, ...this.extractPermission(a.subfeature).flat()];
      }
      return permissionArray;
    });

    return permission;
  }

  /**
  * This updates the user in localstorage. and set the permissions as empty. so that next time fresh permissions will be fetched
  */
  async updatePermissionsInCache(): Promise<User> {
    return new Promise(async (resolve) => {
      const user = await this.getUserInfo().toPromise();
      const globalDetails = await this.getPermissionsForRoles().toPromise();
      // updating the global details to out behavior subject to access them accross the application.
      this.globalDetailsList$.next(globalDetails?.data);
      const self_service_storage = JSON.parse((user?.data?.user?.self_service_storage).replace(/'/g, '"'));
      localStorage.setItem('userEmail', user?.data?.user?.email);
      if (self_service_storage.role) {
        let totalRoles = [];
        if (globalDetails?.data?.global_details) {
          totalRoles = globalDetails?.data?.global_details[0]?.global_detail?.extended_fields?.roles
        }
        user.data.user.permissions = totalRoles.filter(role => {
          if (role.name === self_service_storage.role) {
            return role;
          }
        })
      } else {
        this.currentPermissions = [];
      }
      this.currentPermissions = user?.data?.user?.permissions || [];
      sessionStorage.setItem(AppConstants.userKey, (Utility.Encrypt(JSON.stringify(user.data))));
      localStorage.setItem('role', user.data.user.role.toLowerCase());
      localStorage.setItem('role', self_service_storage.role.toLowerCase());
      this.menuConfigService.onConfigUpdated$.next(true);
      resolve(user?.data);
    })
  }

  getUserInfo(): Observable<OGantryHttpResponse<User>> {
    return this.http.get<OGantryHttpResponse<User>>(ApiUrl.users);
  }

  async getCurrentUser(): Promise<User> {
    const user: User = this.getUserBasicInfoFromCache();
    if (!user) {
      return this.updatePermissionsInCache();
    }
    return Promise.resolve(user);
  }

  private getUserBasicInfoFromCache(): User {
    const user = sessionStorage.getItem(AppConstants.userKey);
    return user ? JSON.parse(Utility.Decrypt(user)) : null;
  }

  getPermissionsForRoles(): Observable<OGantryHttpResponse<GlobalDetailList>> {
    return this.http.get<OGantryHttpResponse<GlobalDetailList>>(ApiUrl.globalDetails, { params: { name: 'UserRolePermissions' } });
  }

  getBackendBuildVersion(): Observable<OGantryHttpResponse<ReleaseVersion>> {
    return this.http.get<OGantryHttpResponse<ReleaseVersion>>(ApiUrl.backendBuildVersion);
  }

  checkAuthSessionStatus(): Observable<any> {
    return this.http.get(ApiUrl.checkAuthSessionStatus);
  }
}
