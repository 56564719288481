/**d
 * @author Ashish Kumar
 */

export const APP_ROUTES = {
  LOGIN: '/auth/login',
  DASHBOARD: '/entities/dashboard',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  LOGOUT: '/logout',
  MY_PROFILE: '/my-profile',
  MANAGE_CLIENT: '/client',
  CREATE_CLIENT: '/client/create',
  EDIT_CLIENT: '/client/create',
  CREATE_PROJECT: '/project/create',
  MANAGE_PROJECT: '/project/manage',
  EDIT_PROJECT: '/project/create',
  MANAGE_WORK_EXCEPTION: '/people/work-exceptions/manage',
  CREATE_WORK_EXCEPTION: '/people/work-exceptions/create',
  EDIT_WORK_EXCEPTION: '/people/work-exceptions/create',
  MANAGE_EMPLOYEE: '/people/employee/manage',
  CREATE_EMPLOYEE: '/people/employee/create',
  EDIT_EMPLOYEE: '/people/employee/create',
  MANAGE_TAG_CATEGORY: '/admin/tag-category/manage',
  CREATE_TAG_CATEGORY: '/admin/tag-category/create',
  EDIT_TAG_CATEGORY: '/admin/tag-category',
  MANAGE_TAG_SUB_CATEGORY: '/admin/tag-sub-category/manage',
  CREATE_TAG_SUB_CATEGORY: '/admin/tag-sub-category/create',
  EDIT_TAG_SUB_CATEGORY: '/admin/tag-sub-category',
  MANAGE_TAG: '/admin/tag/manage',
  CREATE_TAG: '/admin/tag/create',
  EDIT_TAG: '/admin/tag',
  EMPLOYEE_COST: '/people/employee/cost',
  UTILIZATION_COST: '/people/employee/utilization',
  TERMINATE_EMPLOYEE: '/people/employee/terminate',
  MANAGE_ROLE: '/access-control/role/manage',
  CREATE_ROLE: '/access-control/role/create',
  EDIT_ROLE: '/access-control/role/update',
  FORBIDDEN: '/forbidden',
  CREATE_EXPENSE_TYPE: '/admin/expense-type/create',
  EDIT_EXPENSE_TYPE: '/admin/expense-type/create',
  MANAGE_EXPENSE_TYP: '/admin/expense-type/manage',
  CREATE_EMPLOYEE_TYPE: '/admin/employees-type/create',
  EDIT_EMPLOYEE_TYPE: '/admin/employees-type/create',
  MANAGE_EMPLOYEE_TYP: '/admin/employees-type/manage',
  CREATE_POSITION_TYPE: '/admin/position-type/create',
  EDIT_POSITION_TYPE: '/admin/position-type/create',
  MANAGE_POSITION_TYP: '/admin/position-type/manage',
  CREATE_MONTHLY_EXPENSE_TYPE: '/admin/monthly-expense-type/create',
  EDIT_MONTHLY_EXPENSE_TYPE: '/admin/monthly-expense-type/create',
  MANAGE_MONTHLY_EXPENSE_TYPE: '/admin/monthly-expense-type/manage',
  MANAGE_HOLIDAY: '/admin/holidays/manage',
  CREATE_HOLIDAY: '/admin/holidays/create',
  CREATE_WORK_EXCEPTION_TYPE: '/admin/work-exception-type/create',
  EDIT_WORK_EXCEPTION_TYPE: '/admin/work-exception-type/create',
  MANAGE_WORK_EXCEPTION_TYPE: '/admin/work-exception-type/manage',
  CREATE_HOLIDAY_TYPE: '/admin/holiday-type/create',
  EDIT_HOLIDAY_TYPE: '/admin/holiday-type/create',
  MANAGE_HOLIDAY_TYPE: '/admin/holiday-type/manage',
  MANAGE_USER: '/access-control/manage-user',
  ADD_USER: '/access-control/create-user',
  UPDATE_USER: '/access-control/update-user',
  VIEW_STAFFED_POSITION: '/utilization-management/positions-report',
  VIEW_Manage_time: '/people/manage-time'
};
