/**
 * @sunflowerlab
 * @author Ashish Kumar
 */

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpResponse, HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { EMPTY, Subject, throwError } from 'rxjs';
import { ApiUrl, AppConstants, ErrorMessages } from '../constants';
import { catchError, finalize, switchMap, tap} from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { LayoutUtilsService } from './layout-utils.service';
import { AlertType } from '@shared/models/alert-type.enum';
import { AuthService } from '@auth/auth.service';
import { OGantryHttpResponse } from '@shared/models';
import { KtDialogService } from './kt-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  private _refreshSubject: Subject<any> = new Subject<any>();
  constructor(private readonly layoutUtilsService: LayoutUtilsService, private readonly authService: AuthService, private readonly ktDialogService: KtDialogService) { }

  intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.includes("undefined")) {
      return EMPTY as Observable<HttpEvent<any>>;;
    }

    if (!req || !req.url || (/^http/.test(req.url) && !(environment.serverUrl && req.url.startsWith(environment.serverUrl)))) {
      return next.handle(req);
    }
    const ifRefreshTokenRequest = req.url.includes(ApiUrl.renewSession);
    const tokenKey = ifRefreshTokenRequest ? AppConstants.refreshToken : AppConstants.authenticationToken;
    const authToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    let authRequest;
    if (authToken && req.url && !req.url.includes(`${ApiUrl.googleAuth}/callback`)) {
      authRequest = req.clone({
        headers: req.headers.set('Authorization', authToken)
      });
    } else {
      authRequest = req.clone();
    }
    authRequest = authRequest.clone({
      url: environment.serverUrl + authRequest.url
    });
    return next
      .handle(authRequest)
      .pipe(
        tap((event: HttpEvent<string>) => {
          this.errorFunction(event);
        }, (error) => {
          return throwError(error);
        }),
        catchError((error) => {
          if (error.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
          } else {
            if (error.status === 0) {
              this.layoutUtilsService.showActionNotification(ErrorMessages.serverNotAvailable, AlertType.Error);
            } else {
              const httpError: OGantryHttpResponse<any> = error.error;
              if (httpError && httpError?.errors) {
                if (httpError?.error_code !== 401) {
                  this.layoutUtilsService.showActionNotification(httpError.errors.toString(), AlertType.Error);
                }
              }
              if (httpError?.error_code === 401 && !authRequest.url.includes(ApiUrl.renewSession) && !authRequest.url.includes(ApiUrl.checkAuthSessionStatus)) {
                return this._ifTokenExpired().pipe(
                  switchMap(() => {
                    return next.handle(this.updateHeader(req));
                  })
                );
              }
              if (httpError?.error_code === 401 && authRequest.url.includes(ApiUrl.renewSession)) {
                this.authService.logout();
              }
            }
          }
          return throwError(error);
        }),
        finalize(() => {
          this.ktDialogService.hide();
        })
      );

  }

  private _ifTokenExpired() {
    this._refreshSubject.subscribe({
      complete: () => {
        this._refreshSubject = new Subject<any>();
      },
      error:() => {
        this.authService.logout();
      }
    });

    if (this._refreshSubject.observers.length === 1) {
      // Hit refresh-token API passing the refresh token stored into the request
      // to get new access token and refresh token pair
      this.authService.refreshToken().subscribe(this._refreshSubject);
    }
    return this._refreshSubject;
  }

  errorFunction(event) {
    if (event instanceof HttpResponse) {
      return event;
    }
  }

  updateHeader(req) {
    const authToken = localStorage.getItem(AppConstants.authenticationToken);
    req = req.clone({
      headers: req.headers.set("Authorization", `${authToken}`),
      url: environment.serverUrl + req.url
    });
    return req;

  }
}
