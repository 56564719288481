// :::: IMPORTANT - How it works ::::
/**
 * 1. We have default permissions object which gets assigned to all the user who don't have any tenent assigned to them, so we are not rejecting them but allowing them with default read only permissions.
 * 2. Then we have Initial permissions object. Which is the base permission object. If you are removing or adding permission please make sure it applies similarly to default permission object as well.
 * 3. We are keeping track of ids against each permission. Which helps us finding if any change of order in the permission. if you are moving any menu item the id will remain the same and if you are adding new menu/ menu item please MAKE SURE THE ID STARTS IN THE SEQUENCE else it will start breaking.
 * 4. Right after this comment you will see a current max id is mentioned in the comment which tells you which is the last used id in the permissions object. So, in case you wish to add a new item you can easily refer which is the latest id and just increment that id and start adding new items.
 * 5. Once you done MAKE SURE YOU UPDATE THE CURRENT MAX ID's value to the max id you created.
 * 6. We make use of Sequence property to maintain the order of perticular menu item.
 */

// CURRENT MAX ID = 34 USE from 35 for new menu item

export enum Modules {
  MANAGE_PEOPLE = "Manage People",
  PROJECT_STAFFING = 'Project Staffing and Estimating',
  MANAGE_CLIENT = 'Manage Clients',
  MANAGE_PROJECT = 'Manage Projects',
  UTILIZATION_MANAGEMENT = 'Utilization Management',
  BENCH_REPORT = 'Bench Report',
  STAFF_POSITION_REPORT = 'Positions Report',
  BILL_RATE = 'Financial Data',
  BILL_RATE_OPEN_POS = 'Financial Data',
  OPEN_POSITION_PERMISSIONS = 'Lost Revenue Permissions',
  OPEN_POSITION_REPORT = 'Lost Revenue',
  FINANCIAL_FORECASTING = 'Financial Forecasting',
  ACCESS_CONTROL = 'Access Control',
  MANAGE_USER = 'Manage Users',
  CREATE_USER = 'Create User',
  MANAGE_PL = 'Manage P&L',
  COMPARE_PL = 'P&L Comparison',
  ADMINISTRATION = 'Administration',
  MANAGE_EMPLOYEE = 'Manage Employee',
  MANAGE_WORK_EXCEPTION = 'Manage Work Exceptions',
  MANAGE_ROLE = 'Manage Role',
  MANAGE_EXPENSE_TYPE = 'Manage Expense Type',
  MANAGE_EMPLOYEE_TYPE = 'Manage Employee Type',
  MANAGE_POSITION_TYPE = 'Manage Position Type',
  MANAGE_MONTHLY_EXPENSE_TYPE = 'Manage Monthly Expense Type',
  MANAGE_PROJECT_STATUS = 'Manage Project Status',
  MANAGE_HOLIDAY = 'Manage Holidays',
  MANAGE_WORK_EXCEPTION_TYPE = "Manage Work Exception Type",
  MANAGE_FIXED_BID_LOCK = 'Manage Fixed Bid Locks',
  GENERAL_PERMISSIONS = 'General',
  SHARE_FILTERS = 'Share Filter',
  PROJECT_PERMISSIONS = 'Project Permissions',
  STAFFED_POSITION_PERMISSIONS = 'Positions Report Permissions',
  MANAGE_TAG_CATEGORY = 'Manage Tag Category',
  MANAGE_TAG_SUB_CATEGORY = 'Manage Tag Sub Category',
  MANAGE_TAG = 'Manage Tag',
  IMPORT_ACTUALS = 'Import Actuals',
  MANAGE_TIME = 'Manage Time',
  Create_TIME = 'Create Time'
}

export enum Permissions {
  NO_ACCESS = 'No Access ',
  CAN_VIEW = 'View ',
  CAN_MANAGE = 'Manage '
}

export enum ModuleNames {
  CLIENT = 'Client',
  PROJECT = 'Project',
  PL = 'P&L',
  COMPARE_PL = 'P&L Comparison',
  EMPLOYEE = 'Employee',
  WORK_EXCEPTION = 'Work Exceptions',
  IMPORT_ACTUALS = 'Import Actuals',
  ROLE = 'Role',
  EXPENSE_TYPE = 'Expense Type',
  EMPLOYEE_TYPE = 'Employee Type',
  POSITION_TYPE = 'Position Type',
  MONTHLY_EXPENSE_TYPE = 'Monthly Expense Type',
  HOLIDAY = 'Holidays',
  WORK_EXCEPTION_TYPE = "Work Exception Type",
  FIXED_BID_LOCK = "Fixed Bid Lock",
  SHARE_FILTER = "Share Filter",
  PROJECT_STATUS = "Project Status",
  MANAGE_USER = 'Manage User',
  CREATE_USER = 'Create User',
  BILL_RATE_OPEN_POS = 'Financial Data Open Position',
  MANAGE_TAG_CATEGORY = 'Manage Tag Category',
  MANAGE_TAG_SUB_CATEGORY = 'Manage Tag Sub Category',
  MANAGE_TAG = 'Manage Tag',
  MANAGE_TIME_ENTRIES = 'Manage Time Entries',
  TIME_ENTRIES = 'Time Entries',
  Create_ENTRIES = 'Create Entries'
}

export enum PermissionModules {
  VIEW_CLIENT = 'View Client',
  MANAGE_CLIENT = 'Manage Client',
  VIEW_PROJECT = 'View Project',
  MANAGE_PROJECT = 'Manage Project',
  VIEW_PL = 'View P&L',
  MANAGE_PL = 'Manage P&L',
  VIEW_COMPARE_PL = 'View P&L Comparison',
  MANAGE_COMPARE_PL = 'Manage P&L Comparison',
  VIEW_BENCH_REPORT = 'View Bench Report',
  MANAGE_BENCH_REPORT = 'Manage Bench Report',
  VIEW_OPEN_POSITION_REPORT = 'View Lost Revenue',
  MANAGE_OPEN_POSITION_REPORT = 'Manage Lost Revenue',
  VIEW_STAFFED_POSITION_REPORT = 'View Positions Report',
  MANAGE_STAFFED_POSITION_REPORT = 'Manage Positions Report',
  VIEW_BILL_RATE = 'View Financial Data',
  VIEW_BILL_RATE_OPEN_POS = 'View Financial Data Open Position',
  VIEW_EMPLOYEE = 'View Employee',
  MANAGE_EMPLOYEE = 'Manage Employee',
  VIEW_WORK_EXCEPTION = 'View Work Exceptions',
  MANAGE_WORK_EXCEPTION = 'Manage Work Exceptions',
  VIEW_IMPORT_ACTUALS = "View Import Actuals",
  MANAGE_IMPORT_ACTUALS = "Manage Import Actuals",
  VIEW_ROLE = 'View Role',
  MANAGE_ROLE = 'Manage Role',
  VIEW_EXPENSE_TYPE = 'View Expense Type',
  MANAGE_EXPENSE_TYPE = 'Manage Expense Type',
  VIEW_EMPLOYEE_TYPE = 'View Employee Type',
  MANAGE_EMPLOYEE_TYPE = 'Manage Employee Type',
  VIEW_POSITION_TYPE = 'View Position Type',
  MANAGE_POSITION_TYPE = 'Manage Position Type',
  VIEW_MONTHLY_EXPENSE_TYPE = 'View Monthly Expense Type',
  MANAGE_MONTHLY_EXPENSE_TYPE = 'Manage Monthly Expense Type',
  MANAGE_PROJECT_STATUS = 'Manage Project Status',
  VIEW_HOLIDAY = 'View Holidays',
  MANAGE_HOLIDAY = 'Manage Holidays',
  VIEW_WORK_EXCEPTION_TYPE = 'View Work Exception Type',
  MANAGE_WORK_EXCEPTION_TYPE = 'Manage Work Exception Type',
  MANAGE_FIXED_BID_LOCK = 'Manage Fixed Bid Lock',
  VIEW_FIXED_BID_LOCK = 'View Fixed Bid Lock',
  VIEW_SHARE_FILTER = 'View Share Filter',
  MANAGE_SHARE_FILTER = 'Manage Share Filter',
  VIEW_MANAGE_USER = 'View Manage User',
  CREATE_USER = 'Create User',
  MANAGE_USER = 'Manage User',
  VIEW_MANAGE_TAG_CATEGORY = 'View Manage Tag Category',
  CREATE_TAG_CATEGORY = 'Create Tag Category',
  MANAGE_TAG_CATEGORY = 'Manage Tag Category',
  VIEW_MANAGE_TAG_SUB_CATEGORY = 'View Manage Tag Sub Category',
  CREATE_TAG_SUB_CATEGORY = 'Create Tag Sub Category',
  MANAGE_TAG_SUB_CATEGORY = 'Manage Tag Sub Category',
  VIEW_MANAGE_TAG = 'View Manage Tag',
  CREATE_TAG = 'Create Tag',
  MANAGE_TAG = 'Manage Tag',
  VIEW_TIME_ENTRIES = 'View Time Entries',
  Create_TIME_ENTRIES = 'Manage Create Entries',
  MANAGE_TIME_ENTRIES = 'Manage Time Entries',
  View_Create_Entries = 'View Create Entries',
}


const redirectValue = {
  [PermissionModules.VIEW_CLIENT]: ''
}

// setting up permission object for the users who don't have any roles or permissions
export const defaultPermission = [
  {
    permissions: [
      {
        id: 28,
        sequence: 1,
        feature: Modules.MANAGE_PEOPLE,
        expand: false,
        subfeature: [
          { id: 17, name: Modules.MANAGE_EMPLOYEE, permission: [Permissions.CAN_VIEW + ModuleNames.EMPLOYEE], module: ModuleNames.EMPLOYEE },
          { id: 20, name: Modules.MANAGE_WORK_EXCEPTION, permission: [Permissions.CAN_VIEW + ModuleNames.WORK_EXCEPTION], module: ModuleNames.WORK_EXCEPTION },
          { id: 35, name: Modules.IMPORT_ACTUALS, permission: [Permissions.CAN_VIEW + ModuleNames.IMPORT_ACTUALS], module: ModuleNames.IMPORT_ACTUALS },
          { id: 36, name: Modules.MANAGE_TIME, permission: [Permissions.CAN_VIEW + ModuleNames.MANAGE_TIME_ENTRIES], module: ModuleNames.MANAGE_TIME_ENTRIES },
          // { id: 37, name: Modules.Create_TIME, permission: [Permissions.CAN_MANAGE + ModuleNames.Create_ENTRIES], module: ModuleNames.Create_ENTRIES },
        ],
        allFeatures: []
      },
      {
        id: 1,
        sequence: 2,
        feature: Modules.PROJECT_STAFFING,
        expand: false,
        subfeature: [
          { id: 2, name: Modules.MANAGE_CLIENT, permission: [Permissions.CAN_VIEW + ModuleNames.CLIENT], module: ModuleNames.CLIENT },
          {
            id: 3, feature: Modules.PROJECT_PERMISSIONS, expand: false,
            subfeature: [
              { id: 4, name: Modules.MANAGE_PROJECT, permission: [Permissions.CAN_VIEW + ModuleNames.PROJECT], module: ModuleNames.PROJECT, includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW, Permissions.NO_ACCESS] },
              { id: 5, name: Modules.MANAGE_FIXED_BID_LOCK, permission: [Permissions.CAN_VIEW + ModuleNames.FIXED_BID_LOCK], module: ModuleNames.FIXED_BID_LOCK, includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW] },
              { id: 6, name: Modules.MANAGE_PROJECT_STATUS, permission: [Permissions.CAN_VIEW + ModuleNames.PROJECT_STATUS], module: ModuleNames.PROJECT_STATUS, includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW] }
            ]
          }
        ],
        allFeatures: []
      },
      {
        id: 7,
        sequence: 3,
        feature: Modules.UTILIZATION_MANAGEMENT,
        expand: false,
        subfeature: [
          { id: 8, name: Modules.BENCH_REPORT, permission: [Permissions.CAN_VIEW + Modules.BENCH_REPORT], module: Modules.BENCH_REPORT },
          // { id: 9, name: Modules.OPEN_POSITION_REPORT, permission: [Permissions.CAN_VIEW + Modules.OPEN_POSITION_REPORT], module: Modules.OPEN_POSITION_REPORT },
          {
            id: 9, name: Modules.OPEN_POSITION_PERMISSIONS, exapnd: false, subfeature: [
              { id: 30, name: Modules.OPEN_POSITION_REPORT, permission: [Permissions.CAN_VIEW + Modules.OPEN_POSITION_REPORT], module: Modules.OPEN_POSITION_REPORT, includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW, Permissions.NO_ACCESS] },
              { id: 31, name: Modules.BILL_RATE, permission: [], module: ModuleNames.BILL_RATE_OPEN_POS, includePermissions: [Permissions.CAN_VIEW, Permissions.NO_ACCESS] }
            ]
          },
          {
            id: 10,
            feature: Modules.STAFFED_POSITION_PERMISSIONS,
            expand: false,
            subfeature: [
              {
                id: 11,
                name: Modules.STAFF_POSITION_REPORT,
                permission: [Permissions.CAN_VIEW + Modules.STAFF_POSITION_REPORT],
                module: Modules.STAFF_POSITION_REPORT,
                includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW, Permissions.NO_ACCESS]
              },
              {
                id: 12,
                name: Modules.BILL_RATE,
                permission: [],
                module: Modules.BILL_RATE,
                includePermissions: [Permissions.CAN_VIEW, Permissions.NO_ACCESS]
              }
            ]
          },
        ],
        allFeatures: []
      },
      {
        id: 13,
        sequence: 4,
        feature: Modules.FINANCIAL_FORECASTING,
        expand: false,
        subfeature: [
          { id: 15, name: Modules.MANAGE_PL, permission: [Permissions.CAN_VIEW + ModuleNames.PL], module: ModuleNames.PL },
          { id: 14, name: Modules.COMPARE_PL, permission: [Permissions.CAN_VIEW + ModuleNames.COMPARE_PL], module: ModuleNames.COMPARE_PL }
        ],
        allFeatures: []
      },
      {
        id: 16,
        sequence: 5,
        feature: Modules.ADMINISTRATION,
        expand: false,
        subfeature: [
          { id: 32, name: Modules.MANAGE_TAG_CATEGORY, permission: [Permissions.CAN_VIEW + ModuleNames.MANAGE_TAG_CATEGORY], module: ModuleNames.MANAGE_TAG_CATEGORY },
          { id: 33, name: Modules.MANAGE_TAG_SUB_CATEGORY, permission: [Permissions.CAN_VIEW + ModuleNames.MANAGE_TAG_SUB_CATEGORY], module: ModuleNames.MANAGE_TAG_SUB_CATEGORY },
          { id: 34, name: Modules.MANAGE_TAG, permission: [Permissions.CAN_VIEW + ModuleNames.MANAGE_TAG], module: ModuleNames.MANAGE_TAG },
          { id: 18, name: Modules.MANAGE_EXPENSE_TYPE, permission: [Permissions.CAN_VIEW + ModuleNames.EXPENSE_TYPE], module: ModuleNames.EXPENSE_TYPE },
          { id: 19, name: Modules.MANAGE_MONTHLY_EXPENSE_TYPE, permission: [Permissions.CAN_VIEW + ModuleNames.MONTHLY_EXPENSE_TYPE], module: ModuleNames.MONTHLY_EXPENSE_TYPE },
          { id: 21, name: Modules.MANAGE_WORK_EXCEPTION_TYPE, permission: [Permissions.CAN_VIEW + ModuleNames.WORK_EXCEPTION_TYPE], module: ModuleNames.WORK_EXCEPTION_TYPE },
          { id: 22, name: Modules.MANAGE_HOLIDAY, permission: [Permissions.CAN_VIEW + ModuleNames.HOLIDAY], module: ModuleNames.HOLIDAY },
          { id: 23, name: Modules.MANAGE_EMPLOYEE_TYPE, permission: [Permissions.CAN_VIEW + ModuleNames.EMPLOYEE_TYPE], module: ModuleNames.EMPLOYEE_TYPE },
          { id: 24, name: Modules.MANAGE_POSITION_TYPE, permission: [Permissions.CAN_VIEW + ModuleNames.POSITION_TYPE], module: ModuleNames.POSITION_TYPE },
          // { id: 25, name: Modules.MANAGE_ROLE, permission: [Permissions.CAN_VIEW + ModuleNames.ROLE], module: ModuleNames.ROLE }
        ],
        allFeatures: []
      },
      {
        id: 38,
        sequence: 6,
        feature: Modules.ACCESS_CONTROL,
        expand: false,
        subfeature: [
          { id: 29, name: Modules.MANAGE_USER, permission: [Permissions.CAN_VIEW + ModuleNames.MANAGE_USER], module: ModuleNames.MANAGE_USER },
          { id: 25, name: Modules.MANAGE_ROLE, permission: [Permissions.CAN_VIEW + ModuleNames.ROLE], module: ModuleNames.ROLE }],
        allFeatures: []
      },
      {
        id: 26,
        sequence: 7,
        feature: Modules.GENERAL_PERMISSIONS,
        expand: false,
        subfeature: [
          {
            id: 27,
            name: Modules.SHARE_FILTERS,
            permission: [Permissions.CAN_VIEW + Modules.SHARE_FILTERS],
            module: Modules.SHARE_FILTERS
          }
        ],
        allFeatures: []
      }
    ]
  }
];

export const initialPermissions = [
  {
    id: 28,
    sequence: 1,
    feature: Modules.MANAGE_PEOPLE,
    expand: false,
    subfeature: [
      { id: 17, name: Modules.MANAGE_EMPLOYEE, permission: [], module: ModuleNames.EMPLOYEE },
      { id: 20, name: Modules.MANAGE_WORK_EXCEPTION, permission: [], module: ModuleNames.WORK_EXCEPTION },
      { id: 35, name: ModuleNames.IMPORT_ACTUALS, permission: [], module: ModuleNames.IMPORT_ACTUALS },
      { id: 36, name: Modules.MANAGE_TIME, permission: [], module: ModuleNames.TIME_ENTRIES },
      { id: 37, name: Modules.Create_TIME, permission: [Permissions.CAN_VIEW + ModuleNames.Create_ENTRIES, Permissions.CAN_MANAGE + ModuleNames.Create_ENTRIES], module: ModuleNames.Create_ENTRIES },
    ],
    allFeatures: []
  },
  {
    id: 1,
    sequence: 2,
    feature: Modules.PROJECT_STAFFING,
    expand: false,
    subfeature: [
      { id: 2, name: Modules.MANAGE_CLIENT, permission: [], module: ModuleNames.CLIENT },
      {
        id: 3, feature: Modules.MANAGE_PROJECT, permission: [], expand: false, name: Modules.MANAGE_PROJECT, module: ModuleNames.PROJECT, includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW, Permissions.NO_ACCESS], subfeature: [
          { id: 5, name: Modules.MANAGE_FIXED_BID_LOCK, permission: [], module: ModuleNames.FIXED_BID_LOCK, includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW], isHide: false },
          { id: 6, name: Modules.MANAGE_PROJECT_STATUS, permission: [], module: ModuleNames.PROJECT_STATUS, includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW], isHide: false }
        ]
      }
    ],
    allFeatures: []
  },
  {
    id: 7,
    sequence: 3,
    feature: Modules.UTILIZATION_MANAGEMENT,
    expand: false,
    subfeature: [
      { id: 8, name: Modules.BENCH_REPORT, permission: [], module: Modules.BENCH_REPORT },
      // { id: 9, name: Modules.OPEN_POSITION_REPORT, permission: [], module: Modules.OPEN_POSITION_REPORT },
      {
        id: 9, feature: Modules.OPEN_POSITION_PERMISSIONS, exapnd: false, subfeature: [
          { id: 30, name: Modules.OPEN_POSITION_REPORT, permission: [], module: Modules.OPEN_POSITION_REPORT, includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW, Permissions.NO_ACCESS] },
          { id: 31, name: Modules.BILL_RATE_OPEN_POS, permission: [], module: ModuleNames.BILL_RATE_OPEN_POS, includePermissions: [Permissions.CAN_VIEW, Permissions.NO_ACCESS] }
        ]
      },
      {
        id: 10, feature: Modules.STAFFED_POSITION_PERMISSIONS, expand: false, subfeature: [
          { id: 11, name: Modules.STAFF_POSITION_REPORT, permission: [], module: Modules.STAFF_POSITION_REPORT, includePermissions: [Permissions.CAN_MANAGE, Permissions.CAN_VIEW, Permissions.NO_ACCESS] },
          { id: 12, name: Modules.BILL_RATE, permission: [], module: Modules.BILL_RATE, includePermissions: [Permissions.CAN_VIEW, Permissions.NO_ACCESS] }
        ]
      },
    ],
    allFeatures: []
  },
  {
    id: 13,
    sequence: 4,
    feature: Modules.FINANCIAL_FORECASTING,
    expand: false,
    subfeature: [
      { id: 15, name: Modules.MANAGE_PL, permission: [], module: ModuleNames.PL },
      { id: 14, name: Modules.COMPARE_PL, permission: [], module: ModuleNames.COMPARE_PL }],
    allFeatures: []
  },
  {
    id: 16,
    sequence: 5,
    feature: Modules.ADMINISTRATION,
    expand: false,
    subfeature: [
      { id: 32, name: Modules.MANAGE_TAG_CATEGORY, permission: [], module: ModuleNames.MANAGE_TAG_CATEGORY },
      { id: 18, name: Modules.MANAGE_EXPENSE_TYPE, permission: [], module: ModuleNames.EXPENSE_TYPE },
      { id: 19, name: Modules.MANAGE_MONTHLY_EXPENSE_TYPE, permission: [], module: ModuleNames.MONTHLY_EXPENSE_TYPE },
      { id: 21, name: Modules.MANAGE_WORK_EXCEPTION_TYPE, permission: [], module: ModuleNames.WORK_EXCEPTION_TYPE },
      { id: 22, name: Modules.MANAGE_HOLIDAY, permission: [], module: ModuleNames.HOLIDAY },
      { id: 23, name: Modules.MANAGE_EMPLOYEE_TYPE, permission: [], module: ModuleNames.EMPLOYEE_TYPE },
      { id: 24, name: Modules.MANAGE_POSITION_TYPE, permission: [], module: ModuleNames.POSITION_TYPE }],
    allFeatures: []
  },
  {
    id: 38,
    sequence: 6,
    feature: Modules.ACCESS_CONTROL,
    expand: false,
    subfeature: [
      { id: 29, name: Modules.MANAGE_USER, permission: [], module: ModuleNames.MANAGE_USER },
      { id: 25, name: Modules.MANAGE_ROLE, permission: [], module: ModuleNames.ROLE }],
    allFeatures: []
  },
  {
    id: 26,
    sequence: 7,
    feature: Modules.GENERAL_PERMISSIONS,
    expand: false,
    subfeature: [
      { id: 27, name: Modules.SHARE_FILTERS, permission: [], module: ModuleNames.SHARE_FILTER }
    ],
    allFeatures: []
  }
]
